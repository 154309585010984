<app-menu-admin></app-menu-admin>
<div class="container-fluid">
    <div class="row pt-2" *ngIf="tablaUsados==true">
        <div class="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <h4 class="text-center text-white mb-5 mt-5 fw-100">IPHONES USADOS</h4>
            <button (click)="nuevo()" class="btn mt-2 mb-2 w-25"><i class="fas fa-plus"></i> Nuevo</button>
            <table id="table" class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Modelo</th>
                        <th>Tipo</th>
                        <th>Memoria</th>
                        <th>Bateria</th>
                        <th>Pantalla</th>
                        <th>Color</th>
                        <th>Precio</th>
                        <th>Lugar</th>
                        <th>Descripción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let usado of usados">
                        <td>
                            <button (click)="buscar(usado.idUsado)" class="btn btn-tabla-mobile mt-2"
                                id="btn-modificar-{{usado.idUsado}}"><i class="fas fa-edit"></i></button>
                            <button (click)="eliminar(usado.idUsado)" class="btn btn-tabla-mobile mt-2"
                                id="btn-eliminar-{{usado.idUsado}}"><i class="fas fa-trash"></i></button>
                            <img class="mt-2 loading" id="loading-{{usado.idUsado}}" style="display:none;"
                                src="../../assets/img/loading.gif" height="30" alt="">
                        </td>
                        <td>{{usado.modelo}}</td>
                        <td>{{usado.tipo}}</td>
                        <td>{{usado.interna}}GB</td>
                        <td>{{usado.bateria}}</td>
                        <td>{{usado.pantalla}}</td>
                        <td>{{usado.color}}</td>
                        <td>USD{{usado.precio}}</td>
                        <td>{{usado.lugarEntrega}}</td>
                        <td *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                        <td *ngIf="!usado.descripcion">-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>



    <div class="row" id="form-modificar" *ngIf="formModificar==true">
        <div class="col-12">
            <h4 class="text-center text-white mb-5 fw-100 mt-5">MODIFICAR</h4>
        </div>
        <form class="col-md-4 offset-md-4" action="">
            <div class="form-group">
                <label class="text-white bgprincipal p-2"><i class="fas fa-mobile"></i> Modelo: IPHONE
                    {{usadoSeleccionado.modelo}}</label>
            </div>
            <div class="form-group">
                <label for="tipo" class="text-white">Lugar</label>
                <select class="form-control" [(ngModel)]="tipo" [value]="tipo" name="tipo" id="tipo">
                    <option value="OUTLET">OUTLET</option>
                    <option value="PREMIUM">PREMIUM</option>
                </select>
            </div>
            <div class="form-group">
                <label class="text-white mt-2 mb-1" for="interna"><i class="fa-solid fa-mobile-screen-button"></i>
                    Seleccionar Interna</label>
                <select class="form-control" [(ngModel)]="interna" [value]="interna" name="interna" id="interna">
                    <option value="32">32GB</option>
                    <option value="64">64GB</option>
                    <option value="128">128GB</option>
                    <option value="256">256GB</option>
                    <option value="512">512GB</option>
                    <option value="1024">1024GB</option>
                </select>
            </div>
            <div class="form-group">
                <label for="bateria" class="mt-2 mb-1 text-white"><i class="fas fa-battery-three-quarters"></i>
                    Bateria</label>
                <input type="text" class="form-control" [(ngModel)]="bateria" [value]="bateria" name="bateria"
                    id="bateria" aria-describedby="helpId" placeholder="Bateria">
            </div>
            <div class="form-group">
                <label for="pantalla" class=" mt-2 mb-1 text-white "><i class="fas fa-mobile"></i> Pantalla</label>
                <input type="text " class="form-control " [(ngModel)]="pantalla" [value]="pantalla" name="pantalla"
                    id="pantalla " aria-describedby="helpId " placeholder="Pantalla">
            </div>
            <div class="form-group ">
                <label for="color " class="mt-2 mb-1 text-white "><i class="fas fa-tint "></i> Color</label>
                <input type="text " class="form-control " [(ngModel)]="color " [value]="color " name="color "
                    id="color " aria-describedby="helpId " placeholder="Color ">
            </div>
            <div class="form-group ">
                <label for="precio " class="mt-2 mb-1 text-white "><i class="fas fa-dollar-sign "></i> Precio</label>
                <input type="text " class="form-control " [(ngModel)]="precio " [value]="precio " name="precio "
                    id="precio " aria-describedby="helpId " placeholder="Precio ">
            </div>
            <div class="form-group ">
                <label for="lugar " class="mt-2 mb-1 text-white ">Lugar</label>
                <select class="form-control " [value]="lugarEntrega" [(ngModel)]="lugarEntrega" name="lugarEntrega "
                    id="lugarEntrega ">
                    <option value="La Plata">La Plata</option>
                    <option value="Palermo">Palermo</option>
                    <option value="Olavarria">Olavarría</option>
                    <option value="Pehuajo">Pehuajó</option>
                    <option value="Ayacucho">Ayacucho</option>
                    <option value="Pinamar">Pinamar</option>
                    <option value="Bolivar">Bolivar</option>
                    <option value="Trenque">Trenque Lauquen</option>
                </select>
            </div>
            <div class="form-group">
                <label for="descripcion" class="text-white mt-2 mb-1 "><i class="fas fa-pen"></i> Descripción</label>
                <input type="text" class="form-control" maxlength="50" [(ngModel)]="descripcion" name="descripcion"
                    id="descripcion" aria-describedby="helpId" placeholder="">
            </div>
            <div class="text-center mb-4 ">
                <button class=" btn mt-4 " (click)="volver() " style="margin-right:10px;width: 140px; "
                    type="button "><i class="fas fa-chevron-left "></i> Volver</button>
                <button class=" btn mt-4 " (click)="modificar() " style="margin-left:10px;width: 140px; "
                    type="submit ">Modificar <i class="fas fa-check "></i></button>
            </div>

        </form>
    </div>

    <div class="row " id="form-nuevo " *ngIf="formNuevo==true ">
        <div class="col-12 ">
            <h4 class="text-center text-white mb-5 fw-100 mt-5 ">NUEVO IPHONE</h4>
        </div>
        <div class="col-md-4 offset-md-4">
            <div class="form-group">
                <label for="tipo" class="text-white">Lugar</label>
                <select class="form-control" [(ngModel)]="tipoUsado" [value]="tipoUsado" name="tipoUsado"
                    id="tipoUsado">
                    <option value="OUTLET">OUTLET</option>
                    <option value="PREMIUM">PREMIUM</option>
                </select>
            </div>
        </div>
        <form class="col-md-4 offset-md-4 ">
            <div class="form-group ">
                <label class="text-white mt-2 mb-1 " for="modelo "><i class="fas fa-mobile-alt "></i> Seleccionar
                    modelo</label>
                <select required=" " class="form-control " [(ngModel)]="ordenUsado " [(value)]="ordenUsado "
                    name="ordenUsado " id="ordenUsado ">
                    <option value="-2">7</option>
                    <option value="-1">7 PLUS</option>
                    <option value="0">8</option>
                    <option value="1">SE 2020</option>
                    <option value="2">8 PLUS</option>
                    <option value="4">X</option>
                    <option value="5">XS</option>
                    <option value="6">XS MAX</option>
                    <option value="7">XR</option>
                    <option value="8">11</option>
                    <option value="9">11 PRO</option>
                    <option value="10">11 PRO MAX</option>
                    <option value="11">SE 2022</option>
                    <option value="12">12</option>
                    <option value="13">12 MINI</option>
                    <option value="14">12 PRO</option>
                    <option value="15">12 PRO MAX</option>
                    <option value="16">13</option>
                    <option value="17">13 MINI</option>
                    <option value="18">13 PRO</option>
                    <option value="19">13 PRO MAX</option>
                    <option value="20">14</option>
                    <option value="21">14 PLUS</option>
                    <option value="22">14 PRO</option>
                    <option value="23">14 PRO MAX</option>
                    <option value="24">15</option>
                    <option value="25">15 PLUS</option>
                    <option value="26">15 PRO</option>
                    <option value="27">15 PRO MAX</option>
                    <option value="28">16</option>
                    <option value="29">16 PLUS</option>
                    <option value="30">16 PRO</option>
                    <option value="31">16 PRO MAX</option>
                </select>
            </div>
            <div class="form-group ">
                <label class="text-white mt-2 mb-1 " for="interna "><i class="fas fa-memory "></i> Seleccionar
                    Interna</label>
                <select required=" " class="form-control " [(ngModel)]="internaUsado " name="internaUsado "
                    id="internaUsado ">
                    <option value="32 ">32GB</option>
                    <option value="64 ">64GB</option>
                    <option value="128 ">128GB</option>
                    <option value="256 ">256GB</option>
                    <option value="512 ">512GB</option>
                    <option value="1024 ">1024GB</option>
                </select>
            </div>
            <div class="form-group ">
                <label for="bateria " class="mt-2 mb-1 text-white "><i class="fas fa-battery-three-quarters "></i>
                    Bateria</label>
                <input type="text " required=" " class="form-control " [(ngModel)]="bateriaUsado " name="bateriaUsado "
                    id="bateriaUsado " aria-describedby="helpId " placeholder="Bateria ">
            </div>
            <div class="form-group ">
                <label for="pantalla" class="mt-2 mb-1 text-white "><i class="fas fa-mobile"></i> Pantalla</label>
                <input type="text " required="" class="form-control" [(ngModel)]="pantallaUsado" name="pantallaUsado"
                    id="pantallaUsado" aria-describedby="helpId " placeholder="Pantalla">
            </div>
            <div class="form-group ">
                <label for="color " class="mt-2 mb-1 text-white "><i class="fas fa-tint "></i> Color</label>
                <input type="text " required=" " class="form-control " [(ngModel)]="colorUsado " name="colorUsado "
                    id="colorUsado " aria-describedby="helpId " placeholder="Color ">
            </div>
            <div class="form-group ">
                <label for="precio " class="mt-2 mb-1 text-white "><i class="fas fa-dollar-sign "></i> Precio
                    (USD)</label>
                <input type="text " required=" " class="form-control " [(ngModel)]="precioUsado " name="precioUsado "
                    id="precioUsado " aria-describedby="helpId " placeholder="Precio ">
            </div>
            <div class="form-group ">
                <label class="text-white mt-2 mb-1 " for="interna "><i class="fas fa-map-marker-alt "></i> Seleccionar
                    Lugar</label>
                <select class="form-control " required [(ngModel)]="lugarUsado" name="lugarUsado " id="lugarUsado ">
                    <option value="La Plata">La Plata</option>
                    <option value="Palermo">Palermo</option>
                    <option value="Olavarria">Olavarría</option>
                    <option value="Pehuajo">Pehuajó</option>
                    <option value="Ayacucho">Ayacucho</option>
                    <option value="Pinamar">Pinamar</option>
                    <option value="Bolivar">Bolivar</option>
                    <option value="Trenque">Trenque Lauquen</option>
                </select>
            </div>
            <div class="form-group">
                <label for="descripcionUsado" class="text-white mt-2 mb-1 "><i class="fas fa-pen"></i>
                    Descripción</label>
                <input type="text" class="form-control" maxlength="50" [(ngModel)]="descripcionUsado"
                    name="descripcionUsado" id="descripcionUsado" aria-describedby="helpId" placeholder="">
            </div>
            <div class="text-center mb-4 ">
                <button class=" btn mt-4 " (click)="volver() " style="margin-right:10px;width: 140px; "
                    type="button "><i class="fas fa-chevron-left "></i> Volver</button>
                <button class=" btn mt-4 " (click)="subirNuevo() " style="margin-left:10px;width: 140px; "
                    type="submit ">Guardar <i class="fas fa-check "></i></button>
            </div>

        </form>
    </div>

    <div class="row vh-100 " *ngIf="resultado==true ">
        <div class="d-flex justify-content-center align-items-center w-100 ">
            <div class="col-md-8 ">
                <h5 class="text-center text-white mb-5 fw-100 ">
                    <img src="../../assets/img/done.png " class="d-block mx-auto mb-2 " height="50 " alt=" ">
                    ¡ACTUALIZADO!
                </h5>
            </div>
        </div>
    </div>

    <div class="row vh-100 " *ngIf="resultadoNuevo==true ">
        <div class="d-flex justify-content-center align-items-center w-100 ">
            <div class="col-md-8 ">
                <h5 class="text-center text-white mb-5 fw-100 ">
                    <img src="../../assets/img/done.png " class="d-block mx-auto mb-2 " height="50 " alt=" "> ¡GUARDADO!
                </h5>
            </div>
        </div>
    </div>




</div>