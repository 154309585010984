<nav class="navbar navbar-expand-lg navbar-light p-0">
    <a routerLink="/" class="navbar-brand pl-3  pt-3 pb-3" href="#">
        <img src="../../assets/img/logoTrans.png" height="50" alt="">
    </a>
    <button class="navbar-toggler pl-3  pt-3 pb-3" type="button" data-toggle="collapse"
        data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false"
        aria-label="Toggle navigation">
        <!--<span class="navbar-toggler-icon"></span>-->
        <i class="fas fa-bars"></i>
    </button>


    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item">
                <a routerLink="/nuevos-iphone" class="nav-link" href="#">NUEVOS <span
                        class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a routerLink="/premium" class="nav-link" href="#">USADOS</a>
            </li>
            <li class="nav-item">
                <a routerLink="/ipads-y-macbooks" class="nav-link" href="#">IPADS Y MACBOOKS</a>
            </li>
            <li class="nav-item">
                <a routerLink="/accesorios-apple" class="nav-link" href="#">ACCESORIOS APPLE</a>
            </li>
            <li class="nav-item">
                <a routerLink="/importaciones" class="nav-link" href="#">IMPORTACIONES</a>
            </li>
            <li class="nav-item">
                <a routerLink="/formas-de-pago" class="nav-link" href="#">FORMAS DE PAGO</a>
            </li>
            <li class="nav-item">
                <a routerLink="/cotizar-mi-iphone" class="nav-link" href="#">COTIZAR MI IPHONE</a>
            </li>
            <li class="nav-item">
                <a routerLink="/reparaciones" class="nav-link" href="#">REPARACIONES</a>
            </li>
            <li class="nav-item">
                <a routerLink="/mas-info" class="nav-link" href="#">MÁS INFO</a>
            </li>
        </ul>
    </div>
</nav>