import { ConfiguracionComponent } from './admin/configuracion/configuracion.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { NuevosIphoneComponent } from './nuevos-iphone/nuevos-iphone.component';
import { UsadosIphoneComponent } from './usados-iphone/usados-iphone.component';
import { OutletComponent } from './outlet/outlet.component';
import { CotizariphoneComponent } from './cotizariphone/cotizariphone.component';
import { ReparacionesComponent } from './reparaciones/reparaciones.component';
import { MasInfoComponent } from './mas-info/mas-info.component';
import { ImportacionesComponent } from './importaciones/importaciones.component';
import { LoginComponent } from './admin/login/login.component';
import { IphonesNuevosComponent } from './admin/iphones-nuevos/iphones-nuevos.component';
import { IphonesUsadosComponent } from './admin/iphones-usados/iphones-usados.component';
import { IpadsComponent } from './ipads/ipads.component';
import { PromosComponent } from './promos/promos.component';
import { AdmReparacionesComponent } from './admin/adm-reparaciones/adm-reparaciones.component';
import { FormasPagoComponent } from './formas-pago/formas-pago.component';
import { AccesoriosComponent } from './accesorios/accesorios.component';


const routes: Routes = [
  { path: 'promos', component: PromosComponent },
  { path: 'nuevos-iphone', component: NuevosIphoneComponent },
  { path: 'premium', component: UsadosIphoneComponent },
  { path: 'outlet', component: OutletComponent },
  { path: 'cotizar-mi-iphone', component: CotizariphoneComponent },
  { path: 'reparaciones', component: ReparacionesComponent },
  { path: 'mas-info', component: MasInfoComponent },
  { path: 'importaciones', component: ImportacionesComponent },
  { path: 'admin/acceso', component: LoginComponent },
  { path: 'admin/iphones-nuevos', component: IphonesNuevosComponent },
  { path: 'admin/iphones-usados', component: IphonesUsadosComponent },
  { path: 'admin/opciones', component: ConfiguracionComponent },
  { path: 'ipads-y-macbooks', component: IpadsComponent },
  { path: 'accesorios-apple', component: AccesoriosComponent },
  { path: 'formas-de-pago', component: FormasPagoComponent },
  { path: 'admin/reparaciones', component: AdmReparacionesComponent },
  { path: '', component: InicioComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
