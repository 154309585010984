import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NuevosIphoneComponent } from './nuevos-iphone/nuevos-iphone.component';
import { InicioComponent } from './inicio/inicio.component';
import { MenuComponent } from './menu/menu.component';
import { UsadosIphoneComponent } from './usados-iphone/usados-iphone.component';
import { OutletComponent } from './outlet/outlet.component';
import { BotonContactoComponent } from './boton-contacto/boton-contacto.component';
import { CotizariphoneComponent } from './cotizariphone/cotizariphone.component';
import { ReparacionesComponent } from './reparaciones/reparaciones.component';
import { MasInfoComponent } from './mas-info/mas-info.component';
import { ImportacionesComponent } from './importaciones/importaciones.component';
import { PiePaginaComponent } from './pie-pagina/pie-pagina.component';
import { LoginComponent } from './admin/login/login.component';
import { IphonesNuevosComponent } from './admin/iphones-nuevos/iphones-nuevos.component';
import { IphonesUsadosComponent } from './admin/iphones-usados/iphones-usados.component';
import { IpadsComponent } from './ipads/ipads.component';
import { PromosComponent } from './promos/promos.component';
import { MenuAdminComponent } from './admin/menu-admin/menu-admin.component';
import { ConfiguracionComponent } from './admin/configuracion/configuracion.component';
import { AdmReparacionesComponent } from './admin/adm-reparaciones/adm-reparaciones.component';
import { FormasPagoComponent } from './formas-pago/formas-pago.component';
import { AccesoriosComponent } from './accesorios/accesorios.component';

@NgModule({
  declarations: [
    AppComponent,
    NuevosIphoneComponent,
    InicioComponent,
    MenuComponent,
    UsadosIphoneComponent,
    BotonContactoComponent,
    CotizariphoneComponent,
    ReparacionesComponent,
    MasInfoComponent,
    ImportacionesComponent,
    PiePaginaComponent,
    LoginComponent,
    IphonesNuevosComponent,
    IphonesUsadosComponent,
    IpadsComponent,
    PromosComponent,
    MenuAdminComponent,
    ConfiguracionComponent,
    AdmReparacionesComponent,
    OutletComponent,
    FormasPagoComponent,
    AccesoriosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


