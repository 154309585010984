<app-menu></app-menu>
<div class="container-fluid px-0" id="cont-nuevos">
    <div class="row m-0">
        <div class="col-12 text-white">
            <h1 class="text-center text-black mt-5 mb-4" id="titulo-seccion">IPHONE <span
                    class="tipo-modelo">NUEVOS</span></h1>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-xl-10 offset-xl-1">
            <div class="row">
                <div class="col-md-4 animate__animated animate__fadeInUp" *ngFor="let celular of nuevosCelulares">
                    <div class="pt-2 cont-iphone">
                        <div class="px-5" *ngIf="celular.oferta==1">
                            <p class="text-center etiqueta-oferta"><i class="fas fa-fire"></i> Oferta</p>
                        </div>
                        <img class="d-block mx-auto" src="../../assets/iphone/{{celular.foto}}" height="200" alt="">
                        <h4 class="text-center modelo mt-3 mb-4">IPHONE <span
                                class="tipo-modelo">{{celular.modelo}}</span></h4>
                        <p class="text-center" *ngIf="celular.interna64!=0 && celular.estado==1"><span
                                class="capacidad"><i class="fas fa-memory"></i> 64GB</span> ---> <span
                                class="dolares-precio">USD{{celular.interna64}} <span
                                    *ngIf="configuracion[0].pesos==1">(${{dolarArg*celular.interna64 |
                                    number:'1.0'}})</span></span>
                        </p>
                        <p class="text-center" *ngIf="celular.interna128!=0 && celular.estado==1"><span
                                class="capacidad"><i class="fas fa-memory"></i> 128GB</span> ---> <span
                                class="dolares-precio">USD{{celular.interna128}} <span
                                    *ngIf="configuracion[0].pesos==1">(${{dolarArg*celular.interna128 |
                                    number:'1.0'}})</span></span>
                        </p>
                        <p class="text-center" *ngIf="celular.interna256!=0 && celular.estado==1"><span
                                class="capacidad"><i class="fas fa-memory"></i> 256GB</span> ---> <span
                                class="dolares-precio">USD{{celular.interna256}} <span
                                    *ngIf="configuracion[0].pesos==1">(${{dolarArg*celular.interna256 |
                                    number:'1.0'}})</span></span>
                        </p>
                        <p class="text-center" *ngIf="celular.interna512!=0 && celular.estado==1"><span
                                class="capacidad"><i class="fas fa-memory"></i> 512GB</span> ---> <span
                                class="dolares-precio">USD{{celular.interna512}} <span
                                    *ngIf="configuracion[0].pesos==1">(${{dolarArg*celular.interna512 |
                                    number:'1.0'}})</span></span>
                        </p>
                        <p class="text-center" *ngIf="celular.interna1024!=0 && celular.estado==1"><span
                                class="capacidad"><i class="fas fa-memory"></i> 1024GB</span> ---> <span
                                class="dolares-precio">USD{{celular.interna1024}} <span
                                    *ngIf="configuracion[0].pesos==1">(${{dolarArg*celular.interna512 |
                                    number:'1.0'}})</span></span>
                        </p>
                        <!--  <p>
                        <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + celular.idCelular"  aria-expanded="false" aria-controls="collapseExample">
                            <i class="fas fa-credit-card"></i> Cuotas
                        </button>
                      </p>-->
                        <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{celular.idCelular}}">
                            <div class="card card-body mb-4 bnegro">
                                <div *ngIf="celular.interna64!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i>
                                            64GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de
                                        ${{((dolarArg*celular.interna64)*25/100+dolarArg*celular.interna64)/3 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de
                                        ${{((dolarArg*celular.interna64)*55/100+dolarArg*celular.interna64)/6 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de
                                        ${{((dolarArg*celular.interna64)*84/100+dolarArg*celular.interna64)/12 |
                                        number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="celular.interna128!=0">
                                <div *ngIf="celular.interna128!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i>
                                            128GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de
                                        ${{((dolarArg*celular.interna128)*25/100+dolarArg*celular.interna128)/3 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de
                                        ${{((dolarArg*celular.interna128)*55/100+dolarArg*celular.interna128)/6 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de
                                        ${{((dolarArg*celular.interna128)*84/100+dolarArg*celular.interna128)/12 |
                                        number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="celular.interna256!=0">
                                <div *ngIf="celular.interna256!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i>
                                            256GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de
                                        ${{((dolarArg*celular.interna256)*25/100+dolarArg*celular.interna256)/3 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de
                                        ${{((dolarArg*celular.interna256)*55/100+dolarArg*celular.interna256)/6 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de
                                        ${{((dolarArg*celular.interna256)*84/100+dolarArg*celular.interna256)/12 |
                                        number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="celular.interna512!=0">
                                <div *ngIf="celular.interna512!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i>
                                            512GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de
                                        ${{((dolarArg*celular.interna512)*25/100+dolarArg*celular.interna512)/3 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de
                                        ${{((dolarArg*celular.interna512)*55/100+dolarArg*celular.interna512)/6 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de
                                        ${{((dolarArg*celular.interna512)*84/100+dolarArg*celular.interna512)/12 |
                                        number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="celular.interna1024!=0">
                                <div *ngIf="celular.interna1024!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i>
                                            512GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de
                                        ${{((dolarArg*celular.interna1024)*25/100+dolarArg*celular.interna512)/3 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de
                                        ${{((dolarArg*celular.interna1024)*55/100+dolarArg*celular.interna512)/6 |
                                        number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de
                                        ${{((dolarArg*celular.interna1024)*84/100+dolarArg*celular.interna512)/12 |
                                        number:'1.0'}}</p>
                                </div>
                            </div>
                        </div>
                        <p class="text-center stock" *ngIf="celular.estado==1">Disponible</p>
                        <p class="text-center stock" *ngIf="celular.estado==0">No disponible</p>
                        <p class="text-center">{{celular.descripcion}}</p>
                        <div class="text-center mt-4 mb-4" *ngIf="celular.estado==1">
                            <div class="dropdown">
                                <button class="whatsapp dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                                    style="top: -165px !important;">
                                    <a class="dropdown-item"
                                        href="https://wa.me/542215900681?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20La%20Plata"
                                        target="_blank">
                                        <i class="fas fa-store"></i> La Plata/Palermo
                                    </a>
                                    <a class="dropdown-item"
                                        href="https://wa.me/542396411299?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20Pehuajó"
                                        target="_blank">
                                        <i class="fas fa-store"></i> Pehuajó
                                    </a>
                                    <a class="dropdown-item"
                                        href="https://wa.me/542314570875?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20Bolívar"
                                        target="_blank">
                                        <i class="fas fa-store"></i> Bolívar
                                    </a>
                                    <a class="dropdown-item"
                                        href="https://wa.me/542474440752?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20Salto"
                                        target="_blank">
                                        <i class="fas fa-store"></i> Salto
                                    </a>
                                    <a class="dropdown-item"
                                        href="https://wa.me/542216099524?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20Ayacucho"
                                        target="_blank">
                                        <i class="fas fa-store"></i> Ayacucho
                                    </a>
                                    <a class="dropdown-item"
                                        href="https://wa.me/542284386663?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20Olavarría"
                                        target="_blank">
                                        <i class="fas fa-store"></i> Olavarría
                                    </a>
                                    <a class="dropdown-item"
                                        href="https://wa.me/542392671581?text=Hola%2C%20quiero%20hacerles%20una%20consulta%20por%20el%20modelo%20de%20iPhone%20{{celular.modelo}}%20en%20Trenque%20Lauquen"
                                        target="_blank">
                                        <i class="fas fa-store"></i> Trenque Lauquen
                                    </a>
                                </div>
                            </div>
                            <!-- <a href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20el%20iPhone%20{{celular.modelo}}%20"
                                target="_blank" class="whatsapp"></a> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> -->
    <app-pie-pagina></app-pie-pagina>