import { Component, OnInit } from '@angular/core';
import { OutletService } from './outlet.service';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.css']
})
export class OutletComponent implements OnInit {

  usados = [];
  usadosPalermo = [];
  usadosPehuajo = [];
  usadosAyacucho = [];
  usadosPinamar = [];
  usadosOlavarria = [];
  usadosBolivar = [];
  usadosTrenque = [];
  dolarArg = 0;
  configuracion = [];
  openbox = [];

  constructor(public celularesUsados: OutletService) {

    this.celularesUsados.getJson('https://dolarapi.com/v1/dolares/blue').subscribe((res: any) => {
      this.dolarArg = parseFloat(res['venta']) + 40;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=la plata').subscribe((res: any) => {
      this.usados = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=palermo').subscribe((res: any) => {
      this.usadosPalermo = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=olavarria').subscribe((res: any) => {
      this.usadosOlavarria = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=pehuajo').subscribe((res: any) => {
      this.usadosPehuajo = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=ayacucho').subscribe((res: any) => {
      this.usadosAyacucho = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=pinamar').subscribe((res: any) => {
      this.usadosPinamar = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=bolivar').subscribe((res: any) => {
      this.usadosBolivar = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsados.php?ciudad=trenque').subscribe((res: any) => {
      this.usadosTrenque = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerConfiguracion.php').subscribe((res: any) => {
      this.configuracion = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerOpenBox.php').subscribe((res: any) => {
      this.openbox = res;
    });

  }


  ngOnInit(): void {

  }

}
