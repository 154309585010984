<app-menu></app-menu>
<div class="container-fluid" id="cont-accesorios">
    <div class="row">
        <div class="col-12 text-white">
            <h1 class="text-center text-black mt-5 mb-5" id="titulo-seccion">ACCESORIOS APPLE</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col-md-4" *ngFor="let accesorio of accesorios">
                    <div class="pt-2 cont-producto">
                        <img class="d-block mx-auto" src="../../assets/Ipads/{{accesorio.foto}}" height="200"
                            width="100%" [ngStyle]="{'object-fit':'contain'}" alt="">
                        <h4 class="text-center fw300 mt-5 mb-4">{{accesorio.nombre}}</h4>
                        <p class="text-center"><span class="dolares-precio">USD{{accesorio.precio}}</span><span
                                *ngIf="configuracion[0].pesos==1">(${{dolarArg*accesorio.precio | number:'1.0'}})</span>
                        </p>
                        <!-- <p>
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-accesorio-' + accesorio.idaccesorioortaciones" aria-expanded="false" aria-controls="collapseExample">
                            <i class="fas fa-credit-card"></i> Cuotas
                        </button>
                        </p>
                        <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-accesorio-{{accesorio.idaccesorioortaciones}}">
                            <div class="card card-body mb-4 bnegro">
                                <div>
                                    <p class="text-center text-white">3 Cuotas de ${{((dolarArg*accesorio.precio)*25/100+dolarArg*accesorio.precio)/3 | number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de ${{((dolarArg*accesorio.precio)*55/100+dolarArg*accesorio.precio)/6 | number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*accesorio.precio)*84/100+dolarArg*accesorio.precio)/12 | number:'1.0'}}</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="text-center mt-4 mb-4">
                            <a href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20{{accesorio.nombre}}"
                                target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12">
                    <div class="pt-2 cont-producto">
                        <h1 class="text-center crojo font-weight-lighter">¡MUY PRONTO!</h1>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<app-pie-pagina></app-pie-pagina>